/* You can add global styles to this file, and also import other style files */
// @import "../node_modules/ngx-toastr/toastr.css";
@import "~ngx-toastr/toastr.css";
// @import "../node_modules/angular2-multiselect-dropdown/themes/default.theme.css";
@import "~angular2-multiselect-dropdown/themes/default.theme.css";

.nav-tabs-custom,
.table-responsive table,
.tab-pane.box {
  margin-bottom: 0;
}

.filter-type {
  border: 1px solid #d2d6de;
  padding: 0 10px;
  margin-bottom: 15px;
  legend {
    border: 0;
    font-size: 14px;
    font-weight: bold;
    padding: 0 5px;
    margin-bottom: 0;
    width: auto;
  }
  div {
    margin: 10px 0 7px;
    padding-left: 5px;
  }
}

.tab-content {
  padding: 15px !important;
  .box {
    border-top: none;
    box-shadow: none;
  }
}

.table-responsive table {
  tbody {
    display: block;
    overflow: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    td {
      vertical-align: middle;
    }
  }
}

.alert.alert-warning {
  position: relative;
  padding: 15px;
  margin-bottom: 0;
  border: 1px solid transparent;
  border-radius: 0;

  color: #856404 !important;
  background-color: #fff3cd !important;
  border-color: #ffeeba;
  .alert-link {
    color: #0056b3 !important;
    text-decoration: none;
  }
}
.sub-menu-hight {
  cursor: pointer;
  color: black;
  font-size: medium;
}
.sub-menu-txt {
  cursor: pointer;
  color: gray;
  font-size: medium;
}
.pac-container{
  z-index: 50000 !important;
}
